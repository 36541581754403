<template>
    <div class="container">
        <Header></Header>
        <Calendar
            language="pt"
            id="calendar"
            :data-source="getDataSource"
            @mouse-on-day="mouseOnDay"
        ></Calendar>
        <Legends></Legends>
    </div>
</template>

<script>
import "tippy.js/themes/light.css";
import { tippy } from "vue-tippy";
import Calendar from "v-year-calendar";
import "v-year-calendar/locales/v-year-calendar.pt";
import Legends from "./components/Legends.vue";
import Header from "./components/Header.vue";

export default {
    components: {
        Header,
        Calendar,
        Legends,
    },
    methods: {
        getDataSource: function (year) {
            return fetch(`https://pma-api.celpa.pt/dates/${year}`)
                .then((result) => result.json())
                .then((result) => {
                    if (result) {
                        return result.map((r) => ({
                            startDate: new Date(r.startdate),
                            endDate: new Date(r.enddate),
                            name: r.name,
                            attributes: r.attributes,
                            color: r.color,
                            textcolor: r.textcolor,
                        }));
                    }
                    return [];
                });
        },
        mouseOnDay: function (e) {
            if (e.events.length > 0) {
                console.log(
                    e.events[0],
                    e.events[0].color,
                    e.events[0].startDate,
                    e.events[0].endDate
                );
                var content = "";

                for (var i in e.events) {
                    content +=
                        '<div class="event-tooltip-content">' +
                        '<div class="event-name" style="color:' +
                        e.events[i].color +
                        '">' +
                        e.events[i].name +
                        "</div>" +
                        '<div class="event-details">' +
                        e.events[i].attributes +
                        "</div>" +
                        "</div>";
                }

                if (this.tooltip != null) {
                    this.tooltip.destroy();
                    this.tooltip = null;
                }

                this.tooltip = tippy(e.element, {
                    placement: "right",
                    content: content,
                    animateFill: false,
                    animation: "shift-away",
                    theme: "light",
                    arrow: true,
                });
                this.tooltip.show();
            }
        },
        mouseOutDay: function () {
            if (this.tooltip !== null) {
                this.tooltip.destroy();
                this.tooltip = null;
            }
        },
    },
    data: function () {
        return {
            tooltip: null,
            eventsday: null,
        };
    },
};
</script>
