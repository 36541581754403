<template>
    <div>
        <div>
            <h1>ENTIDADES:</h1>
            <div class="card-group">
                <div v-for="group in Groups" v-bind:key="group.name">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title">{{ group.name }}</h5>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li
                                class="list-group-item"
                                v-for="associate in group.associates"
                                :key="associate.name"
                            >
                                <div
                                    class="associate"
                                    v-bind:style="{
                                        color: associate.textcolor,
                                        backgroundColor: associate.color,
                                    }"
                                >
                                    {{ associate.name }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Groups: [
                {
                    name: "Altri",
                    associates: [
                        {
                            name: "CELBI",
                            location: "Figueira da foz",
                            color: "#38761d",
                            textcolor: "white",
                        },
                        {
                            name: "Biotek",
                            location: "VVR",
                            color: "#00ff00",
                            textcolor: "white",
                        },
                        {
                            name: "CAIMA",
                            location: "CIC",
                            color: "#b6d7a8",
                            textcolor: "white",
                        },
                        {
                            name: "GV Figueira da Foz",
                            location: "Figueira da foz",
                            color: "#38761d",
                            textcolor: "white",
                        },
                        {
                            name: "Central SBM",
                            location: "Figueira da foz",
                            color: "#38761d",
                            textcolor: "white",
                        },
                        {
                            name: "GV Constância",
                            location: "Figueira da foz",
                            color: "#b6d7a8",
                            textcolor: "white",
                        },
                        {
                            name: "GV Ródão Power",
                            location: "Figueira da foz",
                            color: "#00ff00",
                            textcolor: "white",
                        },
                    ],
                },
                {
                    name: "THE NAVIGATOR COMPANY",
                    associates: [
                        {
                            name: "NVG-CIA",
                            location: "CIA",
                            color: "#ffd966",
                            textcolor: "white",
                        },
                        {
                            name: "NVG-CIF",
                            location: "CIF",
                            color: "#ff9900",
                            textcolor: "white",
                        },
                        {
                            name: "NVG-CIS",
                            location: "CIS",
                            color: "#e06666",
                            textcolor: "white",
                        },
                        {
                            name: "NVG-CIVVR",
                            location: "CIVVR",
                            color: "#980000",
                            textcolor: "white",
                        },
                    ],
                },
                {
                    name: "DSSMITH",
                    associates: [
                        {
                            name: "DSSMITH",
                            location: "CIV",
                            color: "#0000ff",
                            textcolor: "white",
                        },
                    ],
                },
                {
                    name: "RENOVA",
                    associates: [
                        {
                            name: "RENOVA",
                            location: "CITM",
                            color: "#b381d9",
                            textcolor: "white",
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style scoped>
div {
    text-align: center;
    margin: auto;
}
li.list-group-item {
    padding: 0;
}
div.card-header {
    width: 265px;
}
div.associate {
    align: center;
    text-align: center;
    width: 100%;
    height: 100%;
}
</style>
